import React, { useState } from "react"
import { Link, graphql } from "gatsby"
import Layout from "../../../components/layout"
import Helmet from "react-helmet"
import ViewAll from "../../../components/ViewAll"
import {
  GroupArrayOfObjects,
  createPathWithCategories,
  parseUrl,
} from "../../../utils/index"
import MoreProdResources from "../../../components/moreprodresources"
import SearchComponent from "../../../components/search/searchperks"

export const query = graphql`
  query VPerksHRAdmins {
    allWpVperksHrAdminPost {
      nodes {
        categories {
          nodes {
            name
            slug
          }
        }
        id
        slug
        title
        uri
        content
        seo {
          title
        }
      }
    }
  }
`

const HRAdmins = ({ data }) => {
  const vperkshradminposts = data.allWpVperksHrAdminPost.nodes

  const [categoryTitle, setCategoryTitle] = useState("")

  var groupedData = GroupArrayOfObjects(vperkshradminposts, "id")
  const categories = Object.keys(groupedData)

  const breadcrumbLinks = [
    {
      name: "Home",
      link: "/",
      order: 1,
    },
    {
      name: "Vantage Perks",
      link: "/vantage-perks/",
      order: 2,
    },
    {
      name: "HR-Administrators",
      link: "/vantage-perks/hr-administrators",
      order: 3,
    },
    {
      name: categoryTitle,
      link: "",
      order: 4,
    },
  ]

  const handleViewAll = e => {
    setCategoryTitle(e.target.id)
  }

  return (
    <Layout includeSearchBar={false}>
      <Helmet>
        {/* <title>{pageTitle}</title>

            <meta name="description" content={metaDesc} />

            <meta property="og:title" content={pageTitle} />

            <meta property="og:description" content={metaDesc} />

            <meta property="twitter:title" content={pageTitle} />
                    
            <meta property="twitter:description" content={metaDesc} />
                    
            <meta property="og:type" content="website"/>
                    
            <meta property="og:url" content="https://www.vantagecircle.com/webinars/"/>

            <meta property="og:site_name" content="Vantage Circle"/>
            <link rel="canonical" href="https://www.vantagecircle.com/webinars/"></link> */}
      </Helmet>
      <>
        <SearchComponent />
        <section className="container max-w-5xl mx-auto px-6 py-5 md:py-10">
          <div className="mb-10">
            <div className="text-center">
              <h1 className="section-title">
                <span className="text-orange">Browse</span> Resources by
                Categories
              </h1>
            </div>
          </div>

          <div className="vc-doc-content mb-8">
            <div className="site-breadcrumb">
              <Link className="text-purple-500 hover:underline" to="/">
                Home
              </Link>
              &nbsp;>&nbsp;
              <Link
                className="text-purple-500 hover:underline"
                to="/vantage-perks/"
              >
                Vantage Perks
              </Link>
              &nbsp;>&nbsp;
              <span>HR Administrators</span>
            </div>
            <h2 className="max-w-5xl section-title ">For HR Administrators</h2>
          </div>

          <div className="container max-w-5xl mx-auto">
            <div className="mb-5">
              <div className="grid gap-14 grid-cols-1 md:grid-cols-2">
                {categories.reverse().map((e, key) => {
                  return (
                    <>
                      <div key={key}>
                        <h3 className="text-xl font-bold text-gray-500 mb-3">
                          {e}
                        </h3>
                        {groupedData[e]
                          .reverse()
                          .slice(0, 3)
                          .map(e => {
                            return (
                              <>
                                <Link
                                  className="text-gray-500 block text-sm md:text-base hover:underline"
                                  to={createPathWithCategories(
                                    e.data.uri,
                                    e.data.categories.nodes[0].slug,
                                    e.data.slug
                                  )}
                                >
                                  {e.data.title}
                                </Link>
                              </>
                            )
                          })}
                        <Link
                          id={e}
                          to={parseUrl(e)}
                          onClick={handleViewAll}
                          className="block mt-4 text-gray-500 text-sm md:text-base font-bold underline cursor-pointer"
                        >
                          View All
                        </Link>
                      </div>
                    </>
                  )
                })}
              </div>
            </div>
          </div>
        </section>
      </>
      <MoreProdResources />
    </Layout>
  )
}

export default HRAdmins
